import React from "react";
import { useQuery } from "react-query";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import AutocompleteMultipleFilter from "core/filters/atoms/AutocompleteMultipleFilter";
import AccountService from "modules/Account/services";
import { getAccountName } from "modules/Account/utils";
import { AccountQuery, IAccount } from "modules/Account/models";
import FilterButton from "ui-kit/components/FilterButton";

interface AccountFilterProps {
  multiple?: boolean;
}

const parseOptions = (items: IAccount[]) => {
  return items
    .filter((account) => !account.meta_data?.removed)
    .map((account) => ({
      id: account.id,
      name: getAccountName(account),
    }));
};

const AccountFilter = ({
  multiple = false,
}: AccountFilterProps): React.ReactElement => {
  const fetchAllAccounts = async () => {
    try {
      const { data } = await AccountService.fetchAllAccounts();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(AccountQuery.all, () =>
    fetchAllAccounts()
  );

  const props = {
    id: "accountId",
    options: parseOptions(data?.results || []),
    isLoading,
    label: "Filter account",
  };

  return (
    <FilterButton
      label="Account"
      id="accountId"
      data={data}
      queryName="person.first_name"
    >
      {multiple ? (
        <AutocompleteMultipleFilter {...props} />
      ) : (
        <AutocompleteSingleFilter {...props} />
      )}
    </FilterButton>
  );
};

AccountFilter.defaultProps = {
  multiple: false,
};

export default AccountFilter;
