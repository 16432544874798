import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LimitReportList from "modules/Report/components/LimitReportList";
import RecentActivityReport from "modules/Report/components/RecentActivityReport";
import ReportContainer from "modules/Report/components/ReportContainer";
import ReportWorkHours from "modules/Report/components/ReportWorkHours";
import TeamAnalytics from "modules/Report/components/TeamAnalytics";
import React from "react";

const Dashboard = (): React.ReactElement => {
  return (
    <>
      <Box marginBottom={12} marginTop={-8}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={6}>
            <LimitReportList />
            <ReportWorkHours />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <RecentActivityReport />
          </Grid>
        </Grid>
      </Box>
      <ReportContainer />
      <Box mt={12}>
        <TeamAnalytics />
      </Box>
    </>
  );
};

export default Dashboard;
