import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import AccountService from "modules/Account/services";
import Table, { TableVariant } from "ui-kit/components/Table";
import ConfigConstant from "core/constants/ConfigConstant";
import { createTableCell } from "core/utils/tableHandler";
import AccountNameWithLogin from "../AccountNameWithLogin";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import RouterUtils from "core/routes/utils";
import { Typography } from "@mui/material";

const heads = [
  {
    id: "accounts",
    percentage: true,
    width: 100,
    label: "Accounts",
  },
];

interface AccountTableSelectProps {
  error: boolean;
  helperText: string;
}

const AccountTableSelect = React.forwardRef<
  HTMLDivElement,
  AccountTableSelectProps
>(function Component(
  { error, helperText }: AccountTableSelectProps,
  ref
): React.ReactElement {
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);

  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);

  const fetchAllAccounts = async () => {
    try {
      const { data } = await AccountService.fetchAccountsWithSearch(
        search,
        page
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading, isFetching } = useQuery(
    ["accounts", search, page],
    () => fetchAllAccounts(),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  // Filter out removed accounts
  const filteredAccounts = React.useMemo(() => {
    if (!data?.results) return [];
    return data.results.filter((account) => !account.meta_data?.removed);
  }, [data?.results]);

  return (
    <>
      {/* TODO: Add account search when on backend */}
      {/* <Box sx={{ mb: 2 }}>
        <KeywordFilter />
      </Box> */}
      {!!error && (
        <Typography color="error" variant="body2">
          {helperText}
        </Typography>
      )}
      <Table
        title=""
        heads={heads}
        variant={[TableVariant.checkable]}
        rows={filteredAccounts.map((account) => {
          return {
            name: account.id.toString(),
            data: [
              createTableCell(
                <AccountNameWithLogin account={account} />,
                null,
                "main",
                TableBodyCell
              ),
            ],
          };
        })}
        count={filteredAccounts.length}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={data?.page || page - 1}
        setPage={setPage}
        isFetching={isFetching}
        isLoading={isLoading}
      />
    </>
  );
});

export default AccountTableSelect;
