import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Dialog, DialogContent } from "@mui/material";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { CampaignQuery } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import CampaignValidation from "modules/Campaign/validations";
import SearchItem from "modules/Search/components/SearchItem";
import {
  ALL_NEW_SEARCH_TABS,
  SHORT_NEW_SEARCH_TABS,
} from "modules/Search/constants";
import { emptySearch, SearchContext } from "modules/Search/context";
import {
  ISearchExtendedFormInput,
  ISearchFormValues,
  ISearchSheetResponse,
  SearchStrategy,
  SearchTabIndex,
} from "modules/Search/models";
import { SearchActionType } from "modules/Search/reducers";
import SearchService from "modules/Search/services";
import SearchUtils from "modules/Search/utils";
import SearchValidations from "modules/Search/validations";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DialogHeader from "ui-kit/components/DialogHeader";

const defaultNextButton = "Save prospects";
// No default values as this is used for new search imports
const initialValues = { search: null };

interface CreateCampaignSearchProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
  campaignId: number;
}

const defaultSelectedTab = SearchTabIndex.linkedin_search;

const CreateCampaignSearch = ({
  open,
  toggleOpen,
  campaignId,
}: CreateCampaignSearchProps): React.ReactElement => {
  const classes = SearchUtils.useCreateSearchStyles();
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = React.useState(defaultSelectedTab);

  const [isLoading, setLoading] = React.useState(false);
  const submitButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const [defaultValues, setDefaultValues] = React.useState(initialValues);

  const handleLoading = (bool: boolean) => {
    setLoading(bool);
    bool
      ? submitButtonRef.current?.setAttribute("disabled", "true")
      : submitButtonRef.current?.removeAttribute("disabled");
  };

  const { dispatch, search } = React.useContext(SearchContext);
  const { columns } = search;

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchExistingSearches = async () => {
    try {
      const { data } = await SearchService.fetchExistingSearches();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataExistingSearch } = useQuery(["searches", "existing"], () =>
    fetchExistingSearches()
  );

  const handleTabClean = () => {
    dispatch({
      type: SearchActionType.SET_COLUMNS,
      payload: emptySearch,
    });
  };

  const handleClose = () => {
    toggleOpen(false);

    // Clean up - close selected tab when closed
    setSelectedTab(defaultSelectedTab);

    // Stop loading
    handleLoading(false);
  };

  const handleSuccess = () => {
    handleClose();

    queryClient.invalidateQueries([CampaignQuery.searches_exist, campaignId]);
    queryClient.invalidateQueries([CampaignQuery.searches, campaignId]);
    queryClient.invalidateQueries([CampaignQuery.interactions, campaignId]);
    snackbarHandler.success("New prospects successfully added!");

    handleTabClean();
  };

  const mutateAddGoogleSheet = useMutation(
    (data: ISearchFormValues) => SearchService.createSearch(data),
    {
      onSuccess: (response) => {
        handleSuccess();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
      onSettled: () => {
        // Stop loading
        handleLoading(false);
      },
    }
  );

  const tabs = dataExistingSearch?.count
    ? ALL_NEW_SEARCH_TABS
    : SHORT_NEW_SEARCH_TABS;

  const sharedProps = {
    isLoading,
    setLoading,
    submitButtonRef,
    handleLoading,
    handleTabClean,
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="new-search-dialog"
      classes={{ paper: classes.paper }}
    >
      <DialogHeader
        title="Add leads"
        helperProps={{ link: RouterConstants.DOCS.CAMPAIGNS.AUDIENCE }}
        onHandleClose={handleClose}
      />
      <DialogContent dividers sx={{ p: 0 }}>
        <>
          {/* Only show when existing search available */}
          {selectedTab === SearchTabIndex.existing_search && (
            <SearchItem
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setDefaultValues={setDefaultValues}
              tabs={tabs}
              FormProps={{
                func: CampaignService.createCampaignSearch,
                format: ({ search }: { search: { id: number } }) => {
                  return {
                    search: search.id,
                    campaign: campaignId,
                  };
                },
                defaultValues,
                schema: CampaignValidation.createCampaignAudienceSchema,
                onSuccess: () => {
                  handleSuccess();
                },
                ...sharedProps,
              }}
              SubmitButtonProps={{
                value: defaultNextButton,
              }}
            />
          )}
          {SearchUtils.LINKEDIN_SEARCH_VARIANTS.includes(selectedTab) && (
            <SearchItem
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setDefaultValues={setDefaultValues}
              tabs={tabs}
              FormProps={{
                func: SearchService.createSearch,
                format: ({
                  searchName: name,
                  url,
                }: ISearchExtendedFormInput) => {
                  dispatch({
                    type: SearchActionType.SET_COLUMNS,
                    payload: { ...emptySearch },
                  });

                  return {
                    name,
                    strategy_data: {
                      url,
                    },
                    strategy: SearchUtils.getLinkedSearchStrategy(url),
                    account: accountId,
                    campaign: campaignId,
                  };
                },
                defaultValues,
                schema: SearchValidations.createSearchSchema,
                onSuccess: () => {
                  handleSuccess();
                },
                ...sharedProps,
              }}
              SubmitButtonProps={{
                value: defaultNextButton,
              }}
            />
          )}
          {selectedTab === SearchTabIndex.google_sheet && (
            <SearchItem
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setDefaultValues={setDefaultValues}
              tabs={tabs}
              FormProps={{
                func: columns
                  ? SearchService.createSearch
                  : SearchService.fetchSheetFields,
                format: (d: ISearchExtendedFormInput) => {
                  dispatch({
                    type: SearchActionType.SET_COLUMNS,
                    payload: columns ? { ...search } : { ...emptySearch },
                  });

                  return SearchUtils.formatGoogleSheetData(
                    d,
                    search,
                    accountId,
                    campaignId
                  );
                },
                onError: () => {
                  dispatch({
                    type: SearchActionType.SET_COLUMNS,
                    payload: emptySearch,
                  });
                },
                onSuccess: (d: ISearchSheetResponse, credentials) => {
                  const newData = {
                    columns: d.columns,
                    searchName: credentials.name,
                    url: credentials.strategy_data.url,
                  };

                  if (columns) {
                    handleSuccess();
                  } else {
                    // If only profile_url present, no need to match columns
                    if (newData.columns?.length === 0) {
                      mutateAddGoogleSheet.mutate({
                        name: newData.searchName,
                        account: accountId,
                        campaign: campaignId,
                        strategy:
                          SearchStrategy[
                            "inevitable.strategy.search.GoogleSheetsStrategy"
                          ],
                        strategy_data: {
                          url: newData.url,
                        },
                      });
                    } else {
                      // Stop loading
                      handleLoading(false);

                      // Set search data
                      dispatch({
                        type: SearchActionType.SET_COLUMNS,
                        payload: newData,
                      });
                    }
                  }
                },
                schema: columns
                  ? SearchValidations.matchPlaceholderSchema(columns)
                  : CampaignValidation.createCampaignAudienceSchema,
                defaultValues,
                ...(!columns && {
                  schema: SearchValidations.createSearchSchema,
                }),
                ...sharedProps,
              }}
              SubmitButtonProps={{
                value: columns ? defaultNextButton : "Next: Match placeholders",
                endIcon: columns ? null : <ArrowForwardIcon />,
              }}
            />
          )}
        </>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCampaignSearch;
