import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import {
  CampaignQuery,
  CreateCampaignSteps,
  ICreateCampaignProps,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { INTERACTION_CONNECTION_FILTER_OPTIONS } from "modules/Interaction/constants";
import { SearchStatusMatchToVariant } from "modules/Search/models";
import IntegrationAccordion from "modules/Webhook/components/IntegrationAccordion";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import BlankStepView from "ui-kit/components/BlankStepView";
import Loader from "ui-kit/components/Loader";
import CampaignAudienceList from "../CampaignAudienceList";
import CreateCampaignSearch from "../CreateCampaignSearch";

const pageSize = 20;

const CreateAudience = (props: ICreateCampaignProps): React.ReactElement => {
  const { campaignId } = props;

  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const [isAudienceEmpty, setIsAudienceEmpty] = React.useState<boolean | null>(
    null
  );

  const [isSearchUnfinished, setIsSearchUnfinished] = React.useState<
    boolean | null
  >(null);

  const { search } = RouterUtils.getQueryParams(location);
  const { connection } = RouterUtils.parseQueryParams(location);

  const [connectionQuery, setConnectionQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(
      connection,
      INTERACTION_CONNECTION_FILTER_OPTIONS
    )
  );

  React.useEffect(() => {
    const newConnectionQuery = RouterUtils.generateFilterOptionQuery(
      connection,
      INTERACTION_CONNECTION_FILTER_OPTIONS
    );
    setConnectionQuery(newConnectionQuery);
  }, [connection]);

  const onHandleOpen = () => {
    setOpenDialog(true);
  };

  // Need to pass every filter used to the children :/
  const fetchDraftCampaignInteractions = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchDraftCampaignInteractions(
        campaignId,
        p,
        pageSize,
        search,
        connectionQuery
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const {
    data: dataInteractions,
    isLoading: isLoadingInteractions,
    isFetching: isFetchingInteractions,
  } = useQuery(
    [CampaignQuery.interactions, campaignId, page, search, connectionQuery],
    () => fetchDraftCampaignInteractions(page),
    {
      keepPreviousData: true,
      enabled: !!campaignId,
      // Refetch every X seconds if there is an active search running
      refetchInterval: isSearchUnfinished
        ? ConfigConstant.SEARCH_REFRESH_INTERVAL
        : false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataSearches } = useQuery(
    [CampaignQuery.searches, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchCampaignSearches(
          campaignId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      enabled: !!campaignId,
      // Refetch every x seconds if there is an active search running
      refetchInterval: isSearchUnfinished
        ? ConfigConstant.SEARCH_REFRESH_INTERVAL
        : false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    if (!!dataSearches) {
      const newIsSearchUnfinished =
        dataSearches.count > 0 &&
        !!dataSearches.results.find(
          (campaignSearch) =>
            campaignSearch.search.status === SearchStatusMatchToVariant.queue ||
            campaignSearch.search.status ===
              SearchStatusMatchToVariant.processing
        );
      setIsSearchUnfinished(newIsSearchUnfinished);
    }
  }, [dataSearches]);

  React.useEffect(() => {
    if (dataSearches && dataInteractions) {
      // For audience to not be empty we only need either search or interaction to exist
      const newIsAudienceEmpty =
        dataSearches.count === 0 && dataInteractions.count === 0;
      setIsAudienceEmpty(newIsAudienceEmpty);
    }
  }, [dataSearches, dataInteractions]);

  // Skips disable as prospects will be added automatically
  const onSkipAudience = () => {
    const route = RouterConstants.CAMPAIGN.newTab(
      campaignId,
      CreateCampaignSteps.review
    );
    history.push(route);
  };

  // Loading searches and interactions to see if there are any existing already
  if (isAudienceEmpty === null) {
    return <Loader minHeight={200} />;
  }

  return (
    <>
      {/* Display when empty audience */}
      {!!isAudienceEmpty && (
        <BlankStepView
          icon={PeopleOutlineIcon}
          title="Add leads to the campaign"
          subtitle="Add people to the campaign by LinkedIn Search, Sales Navigator, Recruiter or your custom list in Google Sheets."
          footer={<IntegrationAccordion campaignId={campaignId} />}
          ButtonProps={{ text: "Add leads", onClick: onHandleOpen }}
          customBtn={
            <Button
              color="inherit"
              variant="outlined"
              sx={{ marginRight: 1.5 }}
              onClick={onSkipAudience}
            >
              Skip
            </Button>
          }
        />
      )}
      {/* Only show when existing search available */}
      {!isAudienceEmpty && (
        <CampaignAudienceList
          campaignId={campaignId}
          onHandleAdd={onHandleOpen}
          isAudienceEmpty={isAudienceEmpty}
          isSearchUnfinished={isSearchUnfinished}
          isLoading={isLoadingInteractions}
          isFetching={isFetchingInteractions}
          page={page}
          setPage={setPage}
          search={search}
          connectionQuery={connectionQuery}
          dataInteractions={dataInteractions}
        />
      )}

      {/* Show always to have option to add prospects */}
      <CreateCampaignSearch
        open={openDialog}
        toggleOpen={setOpenDialog}
        campaignId={campaignId}
      />
    </>
  );
};

export default CreateAudience;
