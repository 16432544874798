import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { GlobalContext } from "core/context";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import { IProfileClicked } from "core/models";
import { GlobalActionType } from "core/reducers";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { get } from "lodash";
import { CampaignQuery, ICampaignSearches } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import GoogleSheetInteractionList from "modules/Interaction/components/GoogleSheetInteractionList";
import LinkedInInteractionList from "modules/Interaction/components/LinkedInInteractionList";
import { INTERACTION_CONNECTION_FILTER_OPTIONS } from "modules/Interaction/constants";
import { IInteractions } from "modules/Interaction/models";
import SearchLoader from "modules/Search/components/SearchLoader";
import {
  SearchStatusMatchToVariant,
  SearchStrategyVariant,
  SearchVariant,
} from "modules/Search/models";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import FilterButton from "ui-kit/components/FilterButton";
import PageHeader from "ui-kit/components/PageHeader";
import { TableProps } from "ui-kit/components/Table";

interface AudienceListProps {
  campaignId: number;
  onHandleAdd: () => void;
  isAudienceEmpty: boolean | null;
  isSearchUnfinished: boolean | null;
  isLoading: TableProps["isLoading"];
  isFetching: TableProps["isFetching"];
  page: TableProps["page"];
  setPage: TableProps["setPage"];
  search: string;
  connectionQuery: string;
  dataInteractions: IInteractions | undefined;
}

const CampaignAudienceList = ({
  campaignId,
  onHandleAdd,
  isAudienceEmpty,
  isSearchUnfinished,
  isLoading,
  isFetching,
  page,
  setPage,
  search,
  connectionQuery,
  dataInteractions,
}: AudienceListProps): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const [dataClicked, setDataClicked] = React.useState<
    IProfileClicked | undefined
  >(undefined);
  const [resultCount, setResultCount] = React.useState<number>(0);

  const [isGoogleSheet, setIsGoogleSheet] = React.useState<undefined | boolean>(
    undefined
  );

  const {
    dispatch,
    global: { selected },
  } = React.useContext(GlobalContext);

  const dataSearches: ICampaignSearches | undefined = queryClient.getQueryData([
    CampaignQuery.searches,
    campaignId,
  ]);

  React.useEffect(() => {
    if (dataInteractions?.count) {
      setResultCount(dataInteractions.count);
    }
  }, [dataInteractions?.count]);

  const handleClicked = (newDataClicked: IProfileClicked) => {
    setDataClicked(newDataClicked);
  };

  const handleClose = () => {
    setDataClicked(undefined);
  };

  const resetSelected = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  const mutateRemovePersonFromCampaign = useMutation(
    (persons: number[]) =>
      CampaignService.deleteCampaignPersons({
        persons,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CampaignQuery.interactions);
        snackbarHandler.success("Successfully removed!");
        resetSelected();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateRemoveSearchResultFromCampaign = useMutation(
    (search_results: number[]) =>
      CampaignService.deleteCampaignSearchResults({
        search_results,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CampaignQuery.interactions);
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleProspectsRemove = () => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 person.");
    }
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Do you really want to remove ${selected.length} ${
          selected.length === 1 ? "person" : "people"
        } from the audience?`
      )
    ) {
      let selectedPersons: number[] = [];
      let selectedSearchResults: number[] = [];

      const selectedInteractions = dataInteractions
        ? dataInteractions.results.filter((interaction) =>
            selected.map((x) => x.name).includes(interaction.id.toString())
          )
        : [];

      // Iterate over selected interactions and
      // divide based on person / search_result
      selectedInteractions.forEach((interaction) => {
        if (interaction.person) {
          return selectedPersons.push(interaction.person.id);
        }

        if (interaction.search_result) {
          return selectedSearchResults.push(interaction.search_result.id);
        }
      });

      // If any existing persons, remove them
      mutateRemovePersonFromCampaign.mutate(selectedPersons);

      // If any existing search results, remove them
      mutateRemoveSearchResultFromCampaign.mutate(selectedSearchResults);
    }
    return undefined;
  };

  React.useEffect(() => {
    if (!!dataSearches) {
      const variants = dataSearches.results.map((campaignSearch) => {
        return get(
          SearchStrategyVariant,
          `${[campaignSearch.search.strategy]}`
        );
      });

      const newIsGoogleSheet = !variants.includes(SearchVariant.other);
      setIsGoogleSheet(newIsGoogleSheet);
    }
  }, [dataSearches]);

  const resultProps = {
    data: dataInteractions,
    isLoading,
    isFetching,
    page,
    setPage,
    dataClicked,
    handleClose,
    handleClicked,
    campaignId,
    filters: (
      <>
        <KeywordFilter />
        <FilterButton label="Connection" id="connection">
          <AutocompleteSingleFilter
            id="connection"
            options={INTERACTION_CONNECTION_FILTER_OPTIONS}
            label="Connection"
          />
        </FilterButton>
      </>
    ),
  };

  return (
    <>
      {!!isSearchUnfinished &&
        !!dataSearches &&
        dataSearches.results
          .filter(
            (campaignSearch) =>
              campaignSearch.search.status ===
                SearchStatusMatchToVariant.queue ||
              campaignSearch.search.status ===
                SearchStatusMatchToVariant.processing
          )
          .map(({ search }) => (
            <SearchLoader search={search} campaignId={campaignId} />
          ))}
      {!!dataInteractions?.count && (
        <>
          <PageHeader
            title={`${resultCount.toLocaleString()} prospects`}
            titleProps={{ variant: "h6" }}
            customAction={
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                type="button"
                startIcon={<AddIcon />}
                onClick={onHandleAdd}
              >
                Add leads
              </Button>
            }
            selectedActions={
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                type="button"
                startIcon={<DeleteIcon />}
                onClick={handleProspectsRemove}
              >
                Remove
              </Button>
            }
            dividerProps={{
              light: true,
            }}
          />

          {!!dataSearches &&
            isGoogleSheet !== undefined &&
            (isGoogleSheet ? (
              <GoogleSheetInteractionList
                {...resultProps}
                searchData={dataSearches}
              />
            ) : (
              <LinkedInInteractionList {...resultProps} />
            ))}
        </>
      )}
    </>
  );
};

export default CampaignAudienceList;
