import RouterConstants from "core/routes/constants";
import { handleAccountSelect } from "core/utils/accountHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, IAccount } from "modules/Account/models";
import AccountService from "modules/Account/services";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Loader from "ui-kit/components/Loader";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const AccountSelect = (): React.ReactElement => {
  const history = useHistory();
  const { dispatch } = useContext(AccountContext);

  const { data, isLoading } = useQuery(AccountQuery.all, async () => {
    try {
      const response = await AccountService.fetchAllAccounts();
      // Filter out removed accounts
      response.data.results = response.data.results.filter(
        (account) => !account.meta_data?.removed
      );
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  });

  const handleOnSelect = (account: IAccount | undefined) => {
    if (!account) {
      return;
    }

    handleAccountSelect(account, dispatch, history);
  };

  React.useEffect(() => {
    if (!isLoading && data) {
      // No account exists - navigate to account creation
      if (!data.results.length) {
        history.push(RouterConstants.ACCOUNT.NEW_ROOT);
        return;
      }

      // If accounts exists

      // Try to find first active account
      let account = data.results?.find((account) => !!account?.person);

      // If no account already synced, get first
      if (!account) {
        account = data.results[0];
      }

      if (!!account) {
        handleOnSelect(account);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  return <Loader />;
};

export default AccountSelect;
