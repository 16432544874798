import { CountryCodes, IArrayResponse } from "core/models";

export enum SubscriptionStatusColors {
  "active" = "success",
  "canceled" = "error",
  "unpaid" = "warning",
  "past_due" = "error",
  "incomplete" = "warning",
  "trialing" = "success",
  "paused" = "warning",
}

export enum SubscriptionStatus {
  "active" = "active",
  "canceled" = "canceled",
  "unpaid" = "unpaid",
  "past_due" = "past_due",
  "incomplete" = "incomplete",
  "trialing" = "trialing",
  "paused" = "paused",
}

export interface CancelationOption {
  label: string;
  id: CancelationIds;
}

export enum CancelationIds {
  // "return" = "return",
  "use" = "use",
  "feature" = "feature",
  "need" = "need",
  "expensive" = "expensive",
  "result" = "result",
  // "issues" = "issues",
  "other" = "other",
  // "support" = " support",

  // "alternative" = "alternative",
  // "restriction" = "restriction",

  // "client" = "client",

  // "test" = "test",
}

export enum CancelationLabel {
  // "return" = "Just pausing, I'll be back...",
  "use" = "I am not using it enough",
  "feature" = "Missing features",
  "expensive" = "Doesn't fit my budget",
  "need" = "No longer needed",
  "result" = "Not satisfied with the product or my results",
  "other" = "Other",
}

export enum CancelationSteps {
  "reason" = "reason",
  "offer" = "offer",
  "feedback" = "feedback",
  "confirm" = "confirm",
  // "verify" = "verify",
}

export enum CheckoutStatus {
  "success" = "success",
  "cancel" = "cancel",
}

export enum Currencies {
  usd = "usd",
  eur = "eur",
}

export enum CurrencySign {
  usd = "$",
  eur = "€",
}

export enum RecurringInterval {
  month = "month",
  year = "year",
}

export interface ICheckoutSessionFormValues {
  price: string; //  PRICE_ID
  account: number;
  success_url: string;
  cancel_url: string;
}

export interface ICheckoutSession {
  id: string;
}

export enum CustomerPortalFlowVariant {
  "payment_method_update" = "payment_method_update",
  "subscription_cancel" = "subscription_cancel",
}

export interface ICustomerPortalFormValues {
  return_url: string;
  flow_data_type?: CustomerPortalFlowVariant;
}

export interface ICustomerPortal extends ICustomerPortalFormValues {
  id: string;
  url: string;
}

export interface ISubscriptionPlan {
  active: boolean;
  amount: number;
  amount_decimal: string;
  created: string;
  currency: Currencies;
  id: string;
  interval: RecurringInterval;
  interval_count: number;
  nickname: string;
}

export interface ICoupon {
  id: string;
  amount_off: number;
  percent_off: number;
  name: string;
  currency: Currencies;
  duration: string;
  duration_in_months: number;
}

export interface IDiscount {
  coupon: ICoupon;
  subscription: string;
}

export interface ICustomer {
  id: number;
  created: string;
  currency: Currencies;
  discount?: IDiscount;
  owner?: {
    first_name: string;
    id: number;
    username: string;
  };
}

export interface ICustomers extends IArrayResponse {
  results: ICustomer[];
}

export interface ISubscription {
  id: string;
  seat: {
    id: number;
    login: string;
  };
  status: SubscriptionStatus;
  customer: ICustomer;
  current_user_is_owner: boolean;
  canceled_at: string;
  cancel_at: string;
  seat_object_id: number; // account_id
  data?: {
    created: string;
    current_period_end: number;
    current_period_start: number;
    plan: ISubscriptionPlan;
    start_date: number;
    trial_start: number;
    trial_end: number;
    discount?: IDiscount;
  };
  deleted: boolean | null;
}

export interface ISubscriptions extends IArrayResponse {
  results: ISubscription[];
}

export interface IPrice {
  id: string;
  data: {
    created: string;
    unit_amount: number;
    nickname: string;
    currency: Currencies;
    recurring: {
      interval: RecurringInterval;
      interval_count: number;
    };
  };
}

export interface IPrices extends IArrayResponse {
  results: IPrice[];
}

export interface IAccountSubscription {
  created: string;
  id: number;
  login: string;
  is_subscribed: boolean;
  subscriptions: ISubscription[];
}

export interface IAccountSubscriptions extends IArrayResponse {
  results: IAccountSubscription[];
}

export interface ICheckoutUrls {
  success_url: string;
  cancel_url: string;
}

export interface ICancelReasonFormInput {
  options: CancelationIds | undefined;
  reason: string;
}

export interface ISeatFormInput {
  country: {
    id: CountryCodes;
  };
  account: {
    id: number;
  };
}

export interface ISeatFormValues {
  country: CountryCodes;
  seat_content_type: number; // 27
  seat_object_id: number; // account_id
}

export interface IDiscountInputValues {
  code: string;
}
