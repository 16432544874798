import { Box, Divider, Typography } from "@mui/material";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { createTableCell } from "core/utils/tableHandler";
import { ICampaignParams } from "modules/Campaign/models";
import CreateCampaignWebhook from "modules/Webhook/components/CreateCampaignWebhook";
import { WebhookEventLabels, WebhookQuery } from "modules/Webhook/models";
import WebhookService from "modules/Webhook/services";
import WebhookUtils from "modules/Webhook/utils";
import React from "react";
import { useQuery } from "react-query";
import { Chip } from "ui-kit/atoms/Chip";
import PaperHeader from "ui-kit/components/PaperHeader";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const heads = [
  {
    id: "event",
    percentage: true,
    width: 30,
    label: "Event",
  },
  {
    id: "endpoint",
    percentage: true,
    width: 50,
    label: "Endpoint",
  },
  {
    id: "status",
    percentage: true,
    width: 20,
    label: "Status",
  },
];
const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;

const CampaignIntegrations = ({
  campaignId,
}: ICampaignParams): React.ReactElement => {
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);

  const fetchWebhooks = async (p: number) => {
    try {
      const { data } = await WebhookService.fetchCampaignWebhooks(
        p,
        rowsPerPage,
        campaignId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    [WebhookQuery.webhooks, campaignId, page],
    () => fetchWebhooks(page),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignId,
    }
  );

  return (
    <Box sx={{ py: 2 }}>
      <PaperHeader
        title="Webhooks"
        body="Trigger an event with your favorite application when an action happens in this campaign. (ex. Your prospect replies)"
      />

      <CreateCampaignWebhook campaignId={campaignId} />

      {!!data && data.count > 0 && (
        <>
          <Box sx={{ mt: 6 }}>
            <Typography variant="subtitle1">Webhook list</Typography>
          </Box>

          <Table
            title="Campaign webhooks"
            heads={heads}
            rows={
              data?.results
                ? data.results.map((webhook) => {
                    const link = RouterConstants.INTEGRATION.WEBHOOK.detail(
                      webhook.id
                    );
                    const { icon: Icon, ...chipData } =
                      WebhookUtils.getWebhookActiveChipData(webhook.active);
                    return {
                      name: webhook.id.toString(),
                      data: [
                        createTableCell(
                          WebhookEventLabels[webhook.event],
                          link,
                          "default",
                          TableBodyCell
                        ),
                        createTableCell(
                          webhook.endpoint,
                          link,
                          "default",
                          TableBodyCell
                        ),
                        createTableCell(
                          <Chip
                            variant="outlined"
                            size="small"
                            {...chipData}
                            icon={<Icon />}
                          />,
                          link,
                          "element",
                          TableBodyCell
                        ),
                      ],
                    };
                  })
                : []
            }
            count={data?.count || 0}
            // INITIAL_PAGE starts at 1, but Pagination starts at 0
            page={data?.page || page - 1}
            setPage={setPage}
            isFetching={isFetching}
            rowsPerPage={rowsPerPage}
          />
        </>
      )}

      <Divider sx={{ my: 4 }} />

      <PaperHeader
        title="Reverse webhooks"
        body="Add people to the campaign automatically via Zapier or MAKE (Integromat)."
      />
      <Typography
        component="a"
        href={RouterConstants.DOCS.INTEGRATIONS.REVERSE.ALL}
        target="_blank"
        rel="noopener noreferrer"
        variant="body2"
        sx={{ textDecoration: "underline" }}
      >
        How to automatically add people to the campaign
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mt: 1.5 }}>
        <Typography variant="body2" color="textSecondary">
          Campaign ID:
        </Typography>

        <Typography variant="body2" color="textPrimary">
          {campaignId}
        </Typography>
      </Box>
    </Box>
  );
};

export default CampaignIntegrations;
