import PinInput from "core/components/PinInput";
import { enumToArray } from "core/utils/commonHandler";
import {
  AccountLogStrategy,
  CreateAccountStepIndex,
  CreateAccountStepLabels,
  IAccountCurrent,
  IAccountLogStrategy,
  IAccountOnboardingIds,
  ScrapingStatusLabels,
} from "../models";

const MATCH_SCRAPING_STATUS_TO_LABEL = {
  1: ScrapingStatusLabels.available,
  2: ScrapingStatusLabels.busy,
  3: ScrapingStatusLabels.stopped,
  4: ScrapingStatusLabels.restricted,
};

interface StrategyItems {
  [key: string]: IAccountLogStrategy;
}

const createStrategyOption = (
  description: IAccountLogStrategy["description"],
  label: IAccountLogStrategy["label"],
  strategy: IAccountLogStrategy["strategy"],
  key: IAccountLogStrategy["key"],
  // Disable input for actions where first you need to click a button
  disableInput: boolean,
  input?: IAccountLogStrategy["input"],
  // Layout variant for the description and button (vertical by default)
  layoutVariant?: IAccountLogStrategy["layoutVariant"]
) => ({
  description,
  label,
  strategy,
  key,
  disableInput,
  input,
  layoutVariant,
});

const displayLogin = (login: string | undefined) =>
  login ? <b>{login}</b> : null;

const GET_STRATEGY_ITEMS = (login: string | undefined): StrategyItems => ({
  "session-app": createStrategyOption(
    <>
      LinkedIn sent notification to your signed in devices. If you didn't
      receive the notification,
    </>,
    "Verification needed: Check your LinkedIn app and tap Yes",
    AccountLogStrategy[
      "inevitable.strategy.account.AccountCheckYourLinkedInAppStrategy"
    ],
    "click here to verify using a different method",
    true,
    undefined,
    "horizontal"
  ),
  "session-pin": createStrategyOption(
    <>
      LinkedIn sent this code to: {displayLogin(login)}. Sometimes LinkedIn may
      take a few minutes to send it.
    </>,
    "Verification needed: Enter LinkedIn security code",
    AccountLogStrategy["inevitable.strategy.account.AccountEnterPINStrategy"],
    "Please submit verification PIN from LinkedIn.",
    false,
    PinInput
  ),
  "sms-pin": createStrategyOption(
    <>
      LinkedIn sent this verification code to your phone. Please enter the code
      below and submit.
    </>,
    "Verification needed: Enter LinkedIn Phone Code",
    AccountLogStrategy[
      "inevitable.strategy.account.AccountEnterSMSPinStrategy"
    ],
    "Verification code",
    false
  ),
  "session-password": createStrategyOption(
    <>
      The password to LinkedIn account: {displayLogin(login)} isn't correct.
      Please enter the correct password to continue.
    </>,
    "Incorrect LinkedIn Password",
    AccountLogStrategy[
      "inevitable.strategy.account.AccountEnterPasswordStrategy"
    ],
    "Password (LinkedIn)",
    false
  ),
  "session-username": createStrategyOption(
    <>
      No LinkedIn user for: {displayLogin(login)}. Please enter correct login to
      continue.
    </>,
    "Incorrect LinkedIn Email",
    AccountLogStrategy[
      "inevitable.strategy.account.AccountEnterUsernameStrategy"
    ],
    "Email (LinkedIn)",
    false
  ),
  "session-username-password": createStrategyOption(
    <>
      Your LinkedIn credentials are incorrect and your account can't be
      connected. Please enter the correct login and password to continue.
    </>,
    "LinkedIn credentials are incorrect",
    AccountLogStrategy[
      "inevitable.strategy.account.AccountEnterCredentialsStrategy"
    ],
    [
      { id: "login", label: "Email (LinkedIn)" },
      { id: "password", label: "Password (LinkedIn)" },
    ],
    false
  ),
  "input-pin": createStrategyOption(
    <>
      Please request the verification code. You will receive it to{" "}
      {displayLogin(login)} and need to enter it into the input field that
      appears soon.
    </>,
    "Linkedin requires email verification",
    AccountLogStrategy[
      "inevitable.strategy.account.WaitForAccountPINToResumeStrategy"
    ],
    "Request verification code",
    true,
    PinInput
  ),
  "input-app": createStrategyOption(
    <>
      Click on Start verification and open your LinkedIn app to tap Yes to
      confirm your login.
    </>,
    "Linkedin requires verification on signed in device",
    AccountLogStrategy[
      "inevitable.strategy.account.WaitForAccountCheckYourLinkedInAppStrategy"
    ],
    "Start verification",
    true
  ),
  "input-sms": createStrategyOption(
    <>
      If you have 2FA enabled, you need to enter the code from your
      authenticator app. Otherwise, you receive the code in a SMS and need to
      enter it into the input field that appears soon.
    </>,
    "LinkedIn requires login verification to continue",
    AccountLogStrategy[
      "inevitable.strategy.account.WaitForAccountPINToResumeStrategy"
    ],
    "Start verification",
    true
  ),
  "input-password": createStrategyOption(
    <>
      The password to LinkedIn account: {displayLogin(login)} isn't correct.
      Please enter the correct password to continue.
    </>,
    "Incorrect LinkedIn Password",
    AccountLogStrategy[
      "inevitable.strategy.account.CorrectPasswordToResumeStrategy"
    ],
    "Password (LinkedIn)",
    false
  ),
  "input-username": createStrategyOption(
    <>
      No LinkedIn user for: {displayLogin(login)}. Please enter correct login to
      continue.
    </>,
    "Incorrect LinkedIn Email",
    AccountLogStrategy[
      "inevitable.strategy.account.CorrectUsernameToResumeStrategy"
    ],
    "Email (LinkedIn)",
    false
  ),
  "input-username-password": createStrategyOption(
    <>
      Your LinkedIn credentials are incorrect and your account can't be
      connected. Please enter the correct login and password combination to
      continue.
    </>,
    "LinkedIn credentials are incorrect",
    AccountLogStrategy[
      "inevitable.strategy.account.CorrectCredentialsToResumeStrategy"
    ],
    [
      { id: "login", label: "Email (LinkedIn)" },
      { id: "password", label: "Password (LinkedIn)" },
    ],
    false
  ),
});

const CREATE_ACCOUNT_STEP_LABELS = enumToArray(CreateAccountStepLabels).map(
  ({ id, name }) => ({
    id,
    name,
  })
);
const CREATE_ACCOUNT_STEPS = Object.entries(CreateAccountStepIndex).map(
  ([id, name]) => ({
    id,
    name,
  })
);

export const EMPTY_CURRENT_ACCOUNT: IAccountCurrent = {
  id: 0,
  login: "",
  username: "",
  company: {
    id: 0,
    name: "",
  },
  time_zone: "",
  meta_data: {
    onboarding: {
      [IAccountOnboardingIds.campaign_created]: false,
      [IAccountOnboardingIds.campaign_search]: false,
      [IAccountOnboardingIds.campaign_sequence]: false,
      [IAccountOnboardingIds.campaign_launched]: false,
    },
  },
  created: "",
};

const AccountConstants = {
  STORAGE_KEYS: {
    CURRENT: "currentAccount",
  },
  GET_STRATEGY_ITEMS,
  MATCH_SCRAPING_STATUS_TO_LABEL,
  CREATE_ACCOUNT_STEP_LABELS,
  CREATE_ACCOUNT_STEPS,
  EMPTY_CURRENT_ACCOUNT,
};

export default AccountConstants;
