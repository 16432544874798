import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PaymentUtils from "modules/Payment/utils";
import { ICoupon, IDiscount, ISubscriptionPlan } from "modules/Payment/models";

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
    maxWidth: 200,
  },
  value: {
    color: theme.app.palette.action.color,
    textAlign: "right",
  },
}));

interface DiscountPriceProps {
  plan: ISubscriptionPlan | undefined;
  subscriptionId: string | undefined;
  coupon: ICoupon | undefined;
  customerDiscount: IDiscount | undefined;
}

const DiscountPrice = ({
  plan,
  subscriptionId,
  coupon,
  customerDiscount,
}: DiscountPriceProps): React.ReactElement | null => {
  const classes = useStyles();

  if (!subscriptionId) {
    return null;
  }

  if (!plan) {
    return null;
  }

  if (!coupon) {
    return null;
  }

  // If the customer discount is only per specific subscription, check if it's the current one
  if (
    customerDiscount &&
    customerDiscount?.subscription &&
    subscriptionId !== customerDiscount?.subscription
  ) {
    return null;
  }

  return (
    coupon && (
      <>
        <TableRow>
          <TableCell className={classes.name}>
            {coupon.name} ({PaymentUtils.formatCouponDiscount(coupon)} off )
          </TableCell>
          <TableCell className={classes.value}>
            {PaymentUtils.formatCouponAmountCurrency(
              plan.amount,
              plan.currency,
              coupon
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.name}>Discounted price</TableCell>
          <TableCell className={classes.value}>
            {PaymentUtils.calculatePlanWithDiscount(plan, coupon)}
          </TableCell>
        </TableRow>
      </>
    )
  );
};

export default DiscountPrice;
