import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import {
  formatDateTimeToFull,
  formatDateToFull,
  parseStripeDate,
} from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { createTableCell } from "core/utils/tableHandler";
import { get } from "lodash";
import AccountNameWithLogin from "modules/Account/components/AccountNameWithLogin";
import { AccountQuery } from "modules/Account/models";
import { IAccount } from "modules/Account/models";
import AccountService from "modules/Account/services";
import PaymentDialog from "modules/Payment/components/PaymentDialog";
import {
  IAccountSubscription,
  ICustomer,
  SubscriptionStatus,
} from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import { UserContext } from "modules/User/context";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import PageHeader from "ui-kit/components/PageHeader";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import useUpdateAccountVisibility from "modules/Account/hooks/useUpdateAccountVisibility";

type ParamTypes = {
  accountId: string;
};

const pageSize = 20;
const title = "Billing";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 25,
    label: "Name",
  },
  {
    id: "subscription",
    percentage: true,
    width: 17.5,
    label: "Status",
  },
  {
    id: "permission",
    percentage: true,
    width: 17.5,
    label: "Permission",
  },
  {
    id: "next",
    percentage: true,
    width: 17.5,
    label: "Next billing date",
  },
  {
    id: "date",
    percentage: true,
    width: 17.5,
    label: "Start date",
  },
  {
    id: "actions",
    percentage: true,
    width: 10,
    label: "Actions",
  },
];

const BillingList = (): React.ReactElement => {
  const history = useHistory();
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const queryClient = useQueryClient();

  const params = useParams<ParamTypes>();
  const accountId: number = +params.accountId;

  const [customerId, setCustomerId] = useState<ICustomer["id"] | undefined>(
    undefined
  );

  const { user } = React.useContext(UserContext);

  const [selectedAccountId, setSelectedAccountId] = useState<
    number | undefined
  >(accountId || undefined);

  const { mutate: updateVisibility } = useUpdateAccountVisibility({
    onSuccess: () => {
      // Invalidate the accounts query to refetch the updated data
      queryClient.invalidateQueries(AccountQuery.all);
    },
  });

  const fetchSubscription = async (p: number) => {
    try {
      const { data } = await PaymentService.fetchUserSubscriptions(p, pageSize);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isFetching } = useQuery(
    ["subscriptions", page],
    () => fetchSubscription(page),
    {
      keepPreviousData: true,
    }
  );

  const { data: dataAccounts } = useQuery(
    AccountQuery.all,
    async () => {
      try {
        const response = await AccountService.fetchAllAccounts();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !user.anonymous,
    }
  );

  const fetchCustomers = async () => {
    try {
      const { data } = await PaymentService.fetchCustomers();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCustomers } = useQuery(
    ["customers"],
    () => fetchCustomers(),
    {
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (!!dataCustomers) {
      const newCustomerId = get(dataCustomers, "results.0.id");
      if (newCustomerId !== customerId) {
        setCustomerId(newCustomerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCustomers]);

  const mutateOnBillingDetail = useMutation(
    () => {
      const returnUrl = `${ConfigConstant.BASE_URL}${RouterConstants.BILLING.ALL}`;
      if (!customerId) {
        throw new Error("No valid customer exists for your user account.");
      }
      return PaymentService.createCustomerPortalSession(customerId, {
        return_url: returnUrl,
      });
    },
    {
      onSuccess: async (response) => {
        window.location.href = response.data.url;
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleClick = (
    subscriptionId: string | undefined,
    accountId: number | undefined,
    is_subscribed: boolean
  ) => {
    if (subscriptionId) {
      return history.push(RouterConstants.BILLING.detail(subscriptionId));
    }

    if (!subscriptionId && is_subscribed) {
      return mutateOnBillingDetail.mutate();
    }

    setSelectedAccountId(accountId);
  };

  const handleOpenBillingPortal = () => {
    mutateOnBillingDetail.mutate();
  };

  const customAction = (
    <Button
      variant="outlined"
      color="inherit"
      size="small"
      type="button"
      onClick={handleOpenBillingPortal}
    >
      View invoices
    </Button>
  );

  const handleCloseDialog = () => {
    setSelectedAccountId(undefined);
  };

  // Helper function to filter and map account subscriptions
  const getFilteredAccountSubscriptions = (
    subscriptions: IAccountSubscription[] | undefined,
    accounts: IAccount[] | undefined
  ) => {
    if (!subscriptions || !accounts) {
      return [];
    }

    return subscriptions
      .filter((subscription) => {
        const account = accounts.find(
          ({ login }) => login === subscription.login
        );
        return account && !account.meta_data?.removed;
      })
      .map((accountSubscription) => {
        const account = accounts.find(
          ({ login }) => login === accountSubscription.login
        );

        const subscription = accountSubscription.subscriptions.find(
          (sub) => !sub.deleted && sub.status !== SubscriptionStatus.paused
        );

        const ltd =
          !!account?.id &&
          !subscription &&
          ConfigConstant.LTD_ACCOUNTS.includes(account?.id);

        const onHandleClick = () =>
          handleClick(
            subscription?.id,
            account?.id,
            accountSubscription.is_subscribed
          );

        return {
          name: accountSubscription.id.toString(),
          data: [
            createTableCell(
              <AccountNameWithLogin account={account} />,
              onHandleClick,
              "main",
              TableBodyCell
            ),
            createTableCell(
              PaymentUtils.getSubscriptionStatusLabel(
                subscription,
                accountSubscription.is_subscribed,
                ltd
              ),
              onHandleClick,
              "element",
              TableBodyCell
            ),
            createTableCell(
              PaymentUtils.getPaymentOwner(subscription),
              onHandleClick,
              "element",
              TableBodyCell
            ),
            createTableCell(
              subscription?.data?.plan && !subscription.cancel_at
                ? formatDateToFull(
                    parseStripeDate(subscription?.data.current_period_end),
                    true
                  )
                : "-",
              onHandleClick,
              "default",
              TableBodyCell
            ),
            createTableCell(
              subscription?.data?.start_date
                ? formatDateTimeToFull(
                    parseStripeDate(subscription?.data?.start_date),
                    true
                  )
                : formatDateTimeToFull(accountSubscription.created, true),
              onHandleClick,
              "default",
              TableBodyCell
            ),
            createTableCell(
              !subscription && !ltd ? (
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  sx={{ p: 0, fontSize: "0.8rem" }}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you want to remove this account? This action is irreversible."
                      )
                    ) {
                      if (!account?.id) return;

                      e.stopPropagation();
                      updateVisibility({
                        accountId: account?.id,
                        removed: true,
                      });
                    }
                  }}
                >
                  Remove
                </Button>
              ) : null,
              null,
              "element",
              TableBodyCell
            ),
          ],
        };
      });
  };

  return (
    <>
      <PageHeader
        title={title}
        body="Here you can manage subscriptions for your LinkedIn accounts."
        actionProps={{
          text: "Edit billing details",
          onClick: handleOpenBillingPortal,
          hiddenStartIcon: true,
        }}
        customAction={customAction}
      />
      <Table
        title={title}
        heads={heads}
        rows={getFilteredAccountSubscriptions(
          data?.results,
          dataAccounts?.results
        )}
        count={
          data?.results.filter((subscription) => {
            const account = dataAccounts?.results.find(
              ({ login }) => login === subscription.login
            );
            return account && !account.meta_data?.removed;
          }).length || 0
        }
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={data?.page || page - 1}
        setPage={setPage}
        isFetching={isFetching}
        rowsPerPage={pageSize}
      />

      <PaymentDialog
        accountId={selectedAccountId}
        accountHasProxy={
          !!dataAccounts?.results.find(
            (account) => account.id === selectedAccountId
          )?.proxy_id
        }
        open={!!selectedAccountId}
        onClose={handleCloseDialog}
      />
    </>
  );
};

export default BillingList;
