import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { IAccountMetaData } from "modules/Account/models";
import { useMutation } from "react-query";
import AccountService from "../services";

interface IUpdateAccountVisibilityParams {
  accountId: number;
  removed: boolean;
}

interface IUpdateAccountVisibilityOptions {
  onSuccess?: () => void;
}

const useUpdateAccountVisibility = (
  options?: IUpdateAccountVisibilityOptions
) => {
  const mutateAccountVisibility = useMutation(
    (data: IUpdateAccountVisibilityParams) => {
      const meta_data: IAccountMetaData["meta_data"] = {
        removed: data.removed,
        removedAt: data.removed ? new Date().toISOString() : undefined,
      };

      return AccountService.updateAccount(data.accountId, {
        meta_data,
      });
    },
    {
      onSuccess: ({ data }) => {
        options?.onSuccess?.();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return mutateAccountVisibility;
};

export default useUpdateAccountVisibility;
