import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { InteractionsQuery } from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import PersonAvatar from "modules/Person/components/PersonAvatar";
import { PersonAvatarVariants } from "modules/Person/models";
import React from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    padding: theme.spacing(1.5, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  nameSection: {
    flex: "0 0 25%",
    display: "flex",
    alignItems: "center",
  },
  statsSection: {
    flex: "0 0 15%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  statValue: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  percentage: {
    color: theme.palette.text.primary,
  },
}));

interface TeamAnalyticsItemProps {
  accountId: number;
  name: string;
  avatar?: string;
  queryString: string;
}

const TeamAnalyticsItem: React.FC<TeamAnalyticsItemProps> = ({
  accountId,
  name,
  avatar,
  queryString,
}) => {
  const classes = useStyles();
  const { data: stats, isLoading } = useQuery(
    [InteractionsQuery.account_stats, accountId, queryString],
    async () => {
      const { data } = await InteractionService.fetchAccountStats(
        accountId,
        queryString
      );
      return data;
    },
    {
      enabled: !!accountId && !!queryString,
      cacheTime: 1 * 60 * 1000, // 1 minute
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  if (isLoading || !stats) {
    return (
      <ListItem className={classes.listItem}>
        <Box className={classes.nameSection}>
          <ListItemAvatar>
            <PersonAvatar
              src={avatar}
              name={name}
              size="small"
              placeholderVariant={PersonAvatarVariants.account}
              enableMain
            />
          </ListItemAvatar>
          <ListItemText primary={name} />
        </Box>
        <Box className={classes.statsSection}>-</Box>
        <Box className={classes.statsSection}>-</Box>
        <Box className={classes.statsSection}>-</Box>
        <Box className={classes.statsSection}>-</Box>
        <Box className={classes.statsSection}>-</Box>
      </ListItem>
    );
  }

  const getPercentage = (count: number, total: number) => {
    if (total === 0) return 0;
    return Math.round((count / total) * 100);
  };

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.nameSection}>
        <ListItemAvatar sx={{ minWidth: 44 }}>
          <PersonAvatar
            src={avatar}
            name={name}
            size="small"
            placeholderVariant={PersonAvatarVariants.account}
            enableMain
          />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: "body2" }}
        />
      </Box>
      <Box className={classes.statsSection}>
        <Typography variant="body2" className={classes.statValue}>
          {stats.invite_sent.total || 0}
        </Typography>
      </Box>
      <Box className={classes.statsSection}>
        <Typography variant="body2" className={classes.statValue}>
          {stats.invite_accepted.total || 0}
        </Typography>
        <Typography variant="body2" className={classes.percentage}>
          (
          {getPercentage(
            stats.invite_accepted.total || 0,
            stats.invite_sent.total || 0
          )}
          %)
        </Typography>
      </Box>
      <Box className={classes.statsSection}>
        <Typography variant="body2" className={classes.statValue}>
          {stats.msg_sent.total || 0}
        </Typography>
      </Box>
      <Box className={classes.statsSection}>
        <Typography variant="body2" className={classes.statValue}>
          {stats.replies.total || 0}
        </Typography>
        <Typography variant="body2" className={classes.percentage}>
          ({getPercentage(stats.replies.total || 0, stats.msg_sent.total || 0)}
          %)
        </Typography>
      </Box>
      <Box className={classes.statsSection}>
        <Typography variant="body2" className={classes.statValue}>
          {stats.profile_visits.total || 0}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default TeamAnalyticsItem;
