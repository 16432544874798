import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
  Currencies,
  CurrencySign,
  ICustomer,
  IPrice,
} from "modules/Payment/models";
import PaymentUtils from "modules/Payment/utils";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  planPaper: {
    padding: theme.spacing(3, 4),
    borderRadius: 5,
    border: `2px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 0.3s ease",
    width: "100%",
  },
  selectedPlan: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  planDetails: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  planName: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  ribbon: {
    backgroundColor: "rgb(220, 252, 231)",
    color: "rgb(21, 128, 61)",
    fontSize: "0.6rem",
    borderRadius: 4,
    padding: theme.spacing(0.5, 1.5),
  },
  priceBefore: {
    textDecoration: "line-through",
    marginLeft: theme.spacing(1),
  },
  priceInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  creditApplied: {
    fontSize: "0.7rem",
    color: theme.palette.success.main,
  },
}));

interface PlanOptionProps {
  plan: IPrice | undefined;
  isSelected: boolean;
  onSelect: (planId: string) => void;
  currency: Currencies;
  existingCustomer: ICustomer | undefined;
  isCustomPrice?: boolean;
  defaultInterval?: "month" | "year";
  defaultIntervalCount?: number;
  showDiscount?: boolean;
  credit?: string;
}

const PlanOption = ({
  plan,
  isSelected,
  onSelect,
  currency,
  existingCustomer,
  isCustomPrice = false,
  defaultInterval,
  defaultIntervalCount,
  showDiscount = false,
  credit,
}: PlanOptionProps) => {
  const classes = useStyles();

  const getPlanDetails = useMemo(() => {
    if (!plan) {
      return {
        interval: defaultInterval,
        intervalCount: defaultIntervalCount,
        planName: defaultInterval === "year" ? "Annual" : "Monthly",
      };
    }

    const interval = plan.data.recurring.interval;
    const intervalCount = plan.data.recurring.interval_count;
    const formattedPrice = PaymentUtils.formatStripeUnitPrice(
      plan.data.unit_amount
    );
    const monthlyEquivalent = (() => {
      const monthsInInterval =
        interval === "year" ? 12 * intervalCount : intervalCount;
      return PaymentUtils.formatStripeUnitPrice(
        plan.data.unit_amount / monthsInInterval
      );
    })();

    const priceWithDiscount = PaymentUtils.calculatePriceWithDiscount(
      plan.data.unit_amount,
      currency,
      plan.data.recurring,
      showDiscount ? existingCustomer?.discount?.coupon : undefined
    );

    // Calculate credit amount (if any)
    const creditAmount = credit ? parseFloat(credit) : 0;
    const hasCredit = creditAmount < 0; // Credit is stored as negative value

    const monthlyEquivalentWithDiscount = (() => {
      const monthsInInterval =
        interval === "year" ? 12 * intervalCount : intervalCount;
      return priceWithDiscount / monthsInInterval;
    })();

    const getPlanName = () => {
      if (interval === "year" && intervalCount === 1) return "Annual";
      if (interval === "month" && intervalCount === 1) return "Monthly";
      return `Every ${intervalCount} ${interval}s`;
    };

    return {
      interval,
      intervalCount,
      formattedPrice,
      monthlyEquivalent,
      priceWithDiscount,
      monthlyEquivalentWithDiscount,
      planName: getPlanName(),
      hasCredit,
      creditAmount: Math.abs(creditAmount), // Absolute value for display
    };
  }, [
    plan,
    currency,
    existingCustomer,
    defaultInterval,
    defaultIntervalCount,
    showDiscount,
    credit,
  ]);

  return (
    <Paper
      className={clsx(classes.planPaper, {
        [classes.selectedPlan]: isSelected,
      })}
      onClick={() => plan && onSelect(plan.id)}
      elevation={0}
    >
      <Box className={classes.planDetails}>
        <Typography variant="subtitle2" className={classes.planName}>
          {getPlanDetails.planName}
          {getPlanDetails.interval === "year" && (
            <span className={classes.ribbon}>Get 2+ months free</span>
          )}
          {isCustomPrice && (
            <span className={classes.ribbon}>Your custom price</span>
          )}
        </Typography>
        <Box className={classes.priceInfo}>
          <Box display="flex" alignItems="center" gap={0.25}>
            <Typography variant="subtitle2" display="flex" alignItems="center">
              {plan &&
              getPlanDetails.monthlyEquivalentWithDiscount !== undefined ? (
                <>
                  {CurrencySign[currency]}
                  {Math.round(getPlanDetails.monthlyEquivalentWithDiscount)}
                </>
              ) : (
                <Skeleton width={25} />
              )}
            </Typography>
            {!!existingCustomer?.discount &&
              getPlanDetails.monthlyEquivalent &&
              showDiscount && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.priceBefore}
                >
                  {CurrencySign[currency]}
                  {Math.round(getPlanDetails.monthlyEquivalent)}
                </Typography>
              )}
            <Typography color="text.secondary" variant="caption">
              /mo
            </Typography>
          </Box>
          {getPlanDetails.hasCredit && (
            <Typography className={classes.creditApplied}>
              Credit will be applied: {CurrencySign[currency]}
              {getPlanDetails.creditAmount.toFixed(2)}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default PlanOption;
