import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete, {
  autocompleteClasses,
  AutocompleteCloseReason,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button, { buttonClasses } from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputBase from "@mui/material/InputBase";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { handleAccountSelect } from "core/utils/accountHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, IAccount } from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import {
  getAccountName,
  getFullAccountName,
  mergeAccountSub,
} from "modules/Account/utils";
import { IAccountSubscription } from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PersonAvatar from "modules/Person/components/PersonAvatar";
import { PersonAvatarVariants } from "modules/Person/models";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import * as React from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import StatusBadge from "ui-kit/components/StatusBadge";

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    margin: 0,
    color: "inherit",
    fontSize: 13,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    ...theme.app.constants.table.scrollableDiv,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
      },
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid #e1e4e8`,
  boxShadow: "0 8px 24px rgba(149, 157, 165, 0.4)",
  borderRadius: 6,
  width: theme.app.constants.fullDropdownWidth,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  backgroundColor: "#fff",
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

export default function AccountDropdown() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const history = useHistory();

  const { dispatch: dispatchAccount, account } =
    React.useContext(AccountContext);
  const { dispatch: dispatchUser, user } = React.useContext(UserContext);
  const { id: accountId } = account;
  const { id: userId, anonymous } = user;

  const handleDeletedAccount = () => {
    history.push(RouterConstants.ACCOUNT.REMOVED);
  };

  // Update account in the local storage
  const { data: dataAccount } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        const errData = get(err, "response.data");
        // console.log(errData);

        if (accountId !== 0 && get(errData, "detail") === "Not found.") {
          // console.log(accountId);

          handleDeletedAccount();
        }

        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      enabled: !!accountId && !anonymous,
    }
  );

  // Update user in the local storage
  const { data: dataUser } = useQuery(
    ["user"],
    async () => {
      try {
        const response = await UserService.fetchProfile(Number(userId));
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !anonymous && !!userId,
    }
  );

  const { data: dataAccounts } = useQuery(
    [AccountQuery.all],
    async () => {
      try {
        const response = await AccountService.fetchAllAccounts();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
    }
  );

  const fetchSubscription = async () => {
    try {
      const { data } = await PaymentService.fetchAllUserSubscriptions();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataSubscriptions } = useQuery(
    ["subscriptions", userId],
    () => fetchSubscription(),
    {
      keepPreviousData: true,
    }
  );

  const accountName = React.useMemo(() => getAccountName(account), [account]);

  const accountsWithSub = React.useMemo(() => {
    if (!dataAccounts?.results || !dataSubscriptions?.results) {
      return [];
    }

    const accounts = dataAccounts.results
      .filter((account) => !account.meta_data?.removed)
      .map((account) => ({
        ...account,
        subscription: dataSubscriptions.results.find(
          (subscription) => subscription.login === account.login
        ),
      }));

    return accounts;
  }, [dataAccounts?.results, dataSubscriptions?.results]);

  const firstUpdateAccount = React.useRef(true);
  React.useEffect(() => {
    if (dataAccount) {
      if (!firstUpdateAccount.current) {
        return;
      }
      dispatchAccount({
        type: AccountActionType.INITIAL_LOAD_ACCOUNT,
        payload: dataAccount,
      });
      firstUpdateAccount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAccount]);

  const firstUpdateUser = React.useRef(true);
  React.useEffect(() => {
    if (dataUser) {
      if (!firstUpdateUser.current) {
        return;
      }
      dispatchUser({
        type: UserActionType.SET_USER_DATA,
        payload: dataUser,
      });
      firstUpdateUser.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser]);

  // Don't show if no account is selected as current
  if (!accountId) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleSelect = (account: IAccount) => {
    handleAccountSelect(account, dispatchAccount, history);

    // Close popper
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "account-dropdown" : undefined;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Tooltip
          // If multiple LI accounts
          title={accountsWithSub.length > 1 ? "Switch LinkedIn account" : ""}
          placement="bottom"
        >
          <Button
            disableRipple
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              overflow: "hidden",
              padding: theme.spacing(0.75, 2),
              boxShadow: `0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 4%), 0 1px 1.5px 0 rgb(0 0 0 / 2%)`,
              // boxShadow: `rgba(235, 238, 241) 0 0 0 1px`,
              border: "none",
              borderRadius: 40,
              backgroundColor: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              [`& .${buttonClasses.endIcon}`]: {
                marginLeft: theme.spacing(2),
              },
              [theme.breakpoints.down("md")]: {
                width: "100%",
                minWidth: theme.app.constants.dropdownWidthMobile,
              },
              [theme.breakpoints.up("md")]: {
                width: theme.app.constants.fullDropdownWidth,
              },
            }}
            data-cy="account-dropdown-button"
            aria-describedby={id}
            onClick={handleClick}
            endIcon={
              <ExpandMore
                sx={{
                  color: theme.app.palette.action.icon,
                  fontSize: `22px !important`,
                }}
              />
            }
          >
            <>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <StatusBadge account={dataAccount}>
                  <PersonAvatar
                    src={account?.person?.image_base64}
                    name={accountName}
                    size="mini"
                    placeholderVariant={PersonAvatarVariants.account}
                    enableMain
                  />
                </StatusBadge>
                <Box
                  sx={{
                    textAlign: "left",
                    [theme.breakpoints.down("md")]: {
                      maxWidth: theme.app.constants.dropdownWidthMobile,
                    },
                    [theme.breakpoints.up("md")]: {
                      maxWidth: theme.app.constants.dropdownWidth,
                    },
                  }}
                >
                  <Typography
                    color="textPrimary"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "0.85rem",
                    }}
                  >
                    {accountName}{" "}
                    <Box
                      component="span"
                      color="action.color"
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          display: "none",
                        },
                      }}
                    >
                      ({account.company.name})
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </>
          </Button>
        </Tooltip>
        <Tooltip title="Add new LinkedIn account" placement="bottom">
          <Link to={RouterConstants.ACCOUNT.NEW_ROOT}>
            <IconButton data-cy="account-new-button">
              <AddCircleOutlineIcon
                sx={{
                  color: theme.app.palette.action.icon,
                  fontSize: `20px !important`,
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="top">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              filterOptions={(options, { inputValue }) => {
                const input = inputValue.toLowerCase();
                return options.filter((item) => {
                  const name = getFullAccountName(item).toLowerCase();
                  return name.includes(input);
                });
              }}
              options={accountsWithSub}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No results"
              renderOption={(props, option, { selected }) => {
                const name = getAccountName(option);
                const ltd = ConfigConstant.LTD_ACCOUNTS.includes(
                  Number(option?.id)
                );

                const isRequiredSync =
                  !!option && !!option.person && !option.proxy_id;
                const hasActivePlan =
                  option?.subscription?.is_subscribed || ltd;
                return (
                  <ListItem
                    {...props}
                    sx={{
                      gap: 2,
                      alignItems: "center !important",
                      borderRadius: 0,
                    }}
                    data-cy={option.id}
                    onClick={() => handleSelect(option)}
                  >
                    <ListItemAvatar sx={{ minWidth: 34 }}>
                      <PersonAvatar
                        src={option.person?.image_base64}
                        name={name}
                        size="small"
                        placeholderVariant={PersonAvatarVariants.account}
                        enableMain
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        m: 0,
                      }}
                      primary={name}
                      primaryTypographyProps={{
                        sx: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.825rem",
                        },
                      }}
                      secondary={option.company.name}
                      secondaryTypographyProps={{
                        color: "action.color",
                        sx: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.75rem",
                        },
                      }}
                    />
                    {!!hasActivePlan && !!option.input_required && (
                      <Tooltip title="LinkedIn requires your input to login">
                        <Chip
                          size="small"
                          variant="outlined"
                          color="error"
                          label="Needs verification"
                        />
                      </Tooltip>
                    )}
                    {!!hasActivePlan &&
                      !!isRequiredSync &&
                      !option.input_required && (
                        <Tooltip title="Click to finish sync of your account">
                          <Chip
                            size="small"
                            variant="outlined"
                            color="warning"
                            label="Not synced"
                          />
                        </Tooltip>
                      )}
                    {!hasActivePlan && (
                      <Chip
                        size="small"
                        variant="outlined"
                        color="error"
                        label="No active plan"
                      />
                    )}
                  </ListItem>
                );
              }}
              getOptionLabel={(option) => option.login}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter accounts"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
