import { Alert, Box } from "@mui/material";
import RouterConstants from "core/routes/constants";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, AccountTabs } from "modules/Account/models";
import AccountService from "modules/Account/services";
import PaymentService from "modules/Payment/services";
import { UserContext } from "modules/User/context";
import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const InactiveHoursAlert = (): React.ReactElement | null => {
  const { user } = React.useContext(UserContext);

  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const fetchIsWorkHour = async () => {
    try {
      const { data } = await AccountService.fetchAccountIsOnline(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    [AccountQuery.is_online, accountId],
    () => fetchIsWorkHour(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !user.anonymous,
    }
  );

  const fetchAccountSubscription = async () => {
    try {
      const response = await PaymentService.fetchAccountSubscription(accountId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: accountSubscription } = useQuery(
    [AccountQuery.account_subscription, accountId],
    () => fetchAccountSubscription(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  const isSubscriptionActive = useMemo(
    () => accountSubscription?.is_subscribed,
    [accountSubscription]
  );

  // If no data or account is active, don't show anything
  if (!data || data.is_in_work_time_range) {
    return null;
  }

  // If no subscription, don't show the banner
  if (!isSubscriptionActive) {
    return null;
  }

  return (
    <Alert severity="info" sx={{ marginBottom: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        Your campaigns aren't running now as your account is outside of active
        hours. If needed,
        <Box
          component={Link}
          to={RouterConstants.ACCOUNT.detail(accountId, AccountTabs.schedule)}
          sx={{
            textDecoration: "underline",
            minWidth: "auto",
            p: 0,
            ml: 1,
            fontWeight: 600,
            cursor: "pointer",
            color: "inherit",
          }}
        >
          adjust your schedule here
        </Box>
      </Box>
    </Alert>
  );
};

export default InactiveHoursAlert;
