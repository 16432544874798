import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import ConfigConstant from "core/constants/ConfigConstant";
import { CountryIds } from "core/models";
import { IErrorResponse, errorHandler } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { useCustomerly } from "react-live-chat-customerly";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import {
  CancelationIds,
  CancelationLabel,
  CancelationOption,
  CancelationSteps,
} from "../models";
import PaymentService from "../services";
import { useState, useMemo } from "react";
import { dayjs, parseStripeDate } from "core/utils/dateHandler";

const declineOfferBtn = "Decline Offer";

type ParamTypes = {
  subscriptionId: string;
};

const ContactOffer = () => {
  const { open } = useCustomerly();
  return (
    <Button variant="contained" color="primary" onClick={open}>
      Need Help? Contact Us...
    </Button>
  );
};

const DiscountOffer = () => {
  const { subscriptionId } = useParams<ParamTypes>();
  const [error, setError] = useState<string | null>(null);

  // Fetch current subscription
  const { data: currentSubscription } = useQuery(
    ["subscription", subscriptionId],
    async () => {
      try {
        const response = await PaymentService.fetchSubscription(subscriptionId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    }
  );

  // Fetch all user subscriptions
  const { data: allSubscriptions } = useQuery(
    ["subscriptions"],
    async () => {
      try {
        const response = await PaymentService.fetchAllUserSubscriptions();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const isEligibleForDiscount = useMemo(() => {
    if (!currentSubscription?.data) return false;

    // Check if subscription is older than 20 days
    const subscriptionDate = dayjs(
      parseStripeDate(currentSubscription.data.start_date)
    );
    const daysSinceSubscription = dayjs().diff(subscriptionDate, "day");

    if (daysSinceSubscription < 20) {
      setError(
        "Your subscription must be at least 20 days old to be eligible for this discount."
      );
      return false;
    }

    const hasDiscountOnCurrentSubscription =
      currentSubscription?.data?.discount?.coupon;

    if (hasDiscountOnCurrentSubscription) {
      setError(
        "You already use a discount on your current subscription. Please contact us if that's a mistake or you need help."
      );
      return false;
    }

    // Check if any of user's active subscriptions already has this specific discount
    const hasDiscountOnAnySubscription = allSubscriptions?.results?.some(
      (accountSubscription) =>
        accountSubscription.subscriptions.some((subscription) => {
          // Only check active subscriptions
          if (subscription.status !== "active") return false;

          // Only check subscriptions that aren't cancelled
          if (subscription.cancel_at) return false;

          const couponId = subscription.data?.discount?.coupon?.id;

          if (!couponId) return false;

          if (couponId === ConfigConstant.DISCOUNT_CODE_ID) return true;

          if (couponId === ConfigConstant.OLD_DISCOUNT_CODE_ID) return true;

          return false;
        })
    );

    if (hasDiscountOnAnySubscription) {
      setError(
        "You already used this discount for one of your subscriptions. Please contact us if that's a mistake or you need help."
      );
      return false;
    }

    return true;
  }, [currentSubscription, allSubscriptions]);

  const mutateDiscount = useMutation(
    () =>
      PaymentService.applyDiscount(String(subscriptionId), {
        code: String(ConfigConstant.DISCOUNT_CODE_ID),
      }),
    {
      onSuccess: (response) => {
        snackbarHandler.success("Discount code applied successfully");
        window.location.reload();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleClick = () => {
    if (!isEligibleForDiscount) {
      return;
    }
    mutateDiscount.mutate();
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        borderRadius: 2,
        backgroundColor: theme.palette.primary.light,
      })}
      p={6}
    >
      <Typography variant="subtitle2" mb={3} color="action.color">
        Claim your limited-time offer:
      </Typography>
      <Typography variant="h5" fontWeight={600} mb={4.5}>
        {ConfigConstant.DISCOUNT_CODE_LABEL}
      </Typography>
      {error && (
        <Typography variant="body2" color="error" mb={3} textAlign="center">
          {error}
        </Typography>
      )}
      <Button
        color="primary"
        variant="contained"
        size="medium"
        onClick={handleClick}
        disabled={!isEligibleForDiscount}
      >
        Accept This Offer
      </Button>
    </Box>
  );
};

const createOptions = (
  label: CancelationOption["label"],
  id: CancelationOption["id"]
) => ({ label, id });

const EUR_COUNTRIES = [
  CountryIds.at,
  CountryIds.cz, // non-eur, but customers select EUR
  CountryIds.be,
  CountryIds.bg, // non-eur, but customers select EUR
  CountryIds.hr,
  CountryIds.cy,
  CountryIds.ee,
  CountryIds.fi,
  CountryIds.fr,
  CountryIds.de,
  CountryIds.gr,
  CountryIds.ie,
  CountryIds.it,
  CountryIds.lv,
  CountryIds.lt,
  CountryIds.lu,
  CountryIds.mt,
  CountryIds.nl,
  CountryIds.pt,
  CountryIds.sk,
  CountryIds.si,
  CountryIds.es,
];

const PaymentConstants = {
  STATUS_COLORS: {
    success: "success",
    cancel: "error",
  },
  CANCEL_OPTIONS: [
    createOptions(CancelationLabel.use, CancelationIds.use),
    createOptions(CancelationLabel.feature, CancelationIds.feature),
    createOptions(CancelationLabel.result, CancelationIds.result),
    createOptions(CancelationLabel.expensive, CancelationIds.expensive),
    createOptions(CancelationLabel.need, CancelationIds.need),
    createOptions("Other reason", CancelationIds.other),
  ],
  EUR_COUNTRIES,
  CANCEL_STEPS: {
    [CancelationSteps.reason]: {
      title: "We're sorry that you're thinking about cancelling your plan.",
      subtitle: "Can you please help us understand why?",
      btn: "Next",
      component: <></>,
    },
    [CancelationSteps.offer]: {
      // [CancelationIds.return]: demoCallItem,
      [CancelationIds.use]: {
        title: "We're sure we can work something out",
        subtitle: "We might be able to be of value at a different price point.",
        btn: declineOfferBtn,
        component: <DiscountOffer />,
      },
      [CancelationIds.feature]: {
        title: "It's coming soon.",
        subtitle: `There might be something in our product roadmap that could solve
        this. Please reach out to discuss your feature request.
        Here is a discount on the next months, just the time for us to add
        more value.`,
        btn: declineOfferBtn,
        component: <DiscountOffer />,
      },
      [CancelationIds.need]: {
        title: "We're sure we can work something out",
        subtitle: "We might be able to be of value at a different price point.",
        btn: declineOfferBtn,
        component: <DiscountOffer />,
      },
      [CancelationIds.expensive]: {
        title: "Next months are on us",
        subtitle:
          "We understand budgets might be tight, and we want to keep adding value for you. Here is a special discount for you, just the time for us to add more value.",
        btn: declineOfferBtn,
        component: <DiscountOffer />,
      },
      [CancelationIds.result]: {
        title: "Let us help.",
        subtitle:
          "It's probably something we can help with. Let's discuss and try to get you better results.",
        btn: declineOfferBtn,
        component: <ContactOffer />,
      },
      [CancelationIds.other]: {
        title: "Before you go...",
        subtitle:
          "We want to keep adding value for you, here is a discount to help make it a better deal for you.",
        btn: declineOfferBtn,
        component: <DiscountOffer />,
      },
    },
    [CancelationSteps.feedback]: {
      title: "Anything we could do better?",
      subtitle: "Your honest input is the best way to improve our product.",
      btn: "Next",
      component: <></>,
    },
    [CancelationSteps.confirm]: {
      title: "You're all set. Here is what happens next.",
      subtitle:
        "Once your billing period expires, you'll lose access to your data, campaigns and account settings.",
      btn: "Confirm & Cancel",
      component: <></>,
    },
  },
};

export default PaymentConstants;
