import { List, Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AccountQuery } from "modules/Account/models";
import AccountService from "modules/Account/services";
import { ReportContext } from "modules/Report/context";
import { createDateRange, getDateRangeQuery } from "modules/Report/utils";
import PaymentService from "modules/Payment/services";
import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import TeamAnalyticsItem from "./TeamAnalyticsItem";
import { getAccountName } from "modules/Account/utils";
import PageHeader from "ui-kit/components/PageHeader";
import SelectDateRange from "../SelectDateRange";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(3),
  },
  nameColumn: {
    flex: "0 0 25%",
  },
  statsColumn: {
    flex: "0 0 15%",
    textAlign: "right",
  },
  columnLabel: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
}));

const TeamAnalytics: React.FC = () => {
  const classes = useStyles();
  const {
    report: { currentRange: range },
  } = useContext(ReportContext);

  const dateRange = useMemo(
    () => (range ? createDateRange(range) : undefined),
    [range]
  );

  const queryString = useMemo(
    () => (dateRange ? getDateRangeQuery(dateRange) : ""),
    [dateRange]
  );

  const { data: accounts } = useQuery(
    AccountQuery.all,
    async () => {
      const { data } = await AccountService.fetchAllAccounts();
      return data;
    },
    {
      cacheTime: 1 * 60 * 1000, // 1 minute
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  const nonRemovedAccounts = useMemo(() => {
    if (!accounts) return [];
    return accounts.results.filter((account) => !account.meta_data?.removed);
  }, [accounts]);

  const { data: subscriptions } = useQuery(
    ["account_subscriptions"],
    async () => {
      const { data } = await PaymentService.fetchAllUserSubscriptions();
      return data;
    },
    {
      cacheTime: 1 * 60 * 1000, // 1 minute
      enabled: !!accounts && nonRemovedAccounts.length > 1,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const subscribedAccounts = useMemo(() => {
    if (!accounts || !subscriptions) return [];

    const subscribedIds = new Set(
      subscriptions.results
        .filter((sub) => sub.is_subscribed)
        .map((sub) => sub.id)
    );

    return nonRemovedAccounts.filter((account) =>
      subscribedIds.has(account.id)
    );
  }, [accounts, subscriptions, nonRemovedAccounts]);

  if (subscribedAccounts.length <= 1) {
    return null;
  }

  return (
    <>
      <PageHeader
        title="Team Analytics"
        disableBodyComponent
        customAction={<SelectDateRange />}
        hiddenDivider
      />
      <Box className={classes.header}>
        <Box className={classes.nameColumn}>
          <Typography variant="body2" className={classes.columnLabel}>
            Account
          </Typography>
        </Box>
        <Box className={classes.statsColumn}>
          <Typography variant="body2" className={classes.columnLabel}>
            Invites sent
          </Typography>
        </Box>
        <Box className={classes.statsColumn}>
          <Typography variant="body2" className={classes.columnLabel}>
            Accepted
          </Typography>
        </Box>
        <Box className={classes.statsColumn}>
          <Typography variant="body2" className={classes.columnLabel}>
            Messages sent
          </Typography>
        </Box>
        <Box className={classes.statsColumn}>
          <Typography variant="body2" className={classes.columnLabel}>
            Replied
          </Typography>
        </Box>
        <Box className={classes.statsColumn}>
          <Typography variant="body2" className={classes.columnLabel}>
            Profiles visited
          </Typography>
        </Box>
      </Box>
      <List>
        {subscribedAccounts.map((account) => (
          <TeamAnalyticsItem
            key={account.id}
            accountId={account.id}
            name={getAccountName(account)}
            avatar={account.person?.image_base64}
            queryString={queryString}
          />
        ))}
      </List>
    </>
  );
};

export default TeamAnalytics;
