import React from "react";
import KeyIcon from "@mui/icons-material/Key";
import WebhookIcon from "@mui/icons-material/Webhook";
import RouterConstants from "core/routes/constants";
import ConfigConstant from "core/constants/ConfigConstant";
import PageHeader from "ui-kit/components/PageHeader";
import NavItem from "ui-kit/components/NavItem";

const title = "Integrations";

const IntegrationPage = (): React.ReactElement => {
  return (
    <>
      <PageHeader
        title={title}
        body={`Integrate ${ConfigConstant.APP_NAME} with 3,000+ services through Zapier, Make (Integromat) or any custom-made webhook.`}
        helperProps={{
          link: RouterConstants.DOCS.INTEGRATIONS.ALL,
        }}
      />

      <NavItem
        title="Webhooks"
        subtitle="Trigger an event with your favorite application when an action happens. (ex. Your prospect replies)"
        icon={WebhookIcon}
        color="info"
        link={RouterConstants.INTEGRATION.WEBHOOK.ALL}
        divider
      />

      <NavItem
        title="Reverse webhooks"
        subtitle="Add leads automatically to the campaign via Zapier or MAKE (Integromat)."
        icon={KeyIcon}
        color="success"
        link={RouterConstants.INTEGRATION.API}
      />
    </>
  );
};

export default IntegrationPage;
