/* eslint-disable @typescript-eslint/no-explicit-any */
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import RouterConstants from "core/routes/constants";
import { enumToArray } from "core/utils/commonHandler";
import React from "react";
import { IChipColors } from "ui-kit/atoms/Chip";
import {
  CampaignCreateStepLabels,
  CampaignDetailTabs,
  CampaignLocaleNames,
  CreateCampaignStepIndex,
  ExecutionKeys,
  ExecutionLabels,
  ICampaign,
  INewCampaign,
} from "../models";

interface IStatusChipData {
  color: IChipColors;
  icon: any;
  label: ExecutionLabels;
}

const createChipData = (
  color: IChipColors,
  icon: React.FunctionComponent<any>,
  label: ExecutionLabels
): IStatusChipData => ({
  color,
  icon,
  label,
});

const getStatusChipData = (execution: ExecutionKeys): IStatusChipData => {
  switch (execution) {
    case ExecutionKeys.RU:
      return createChipData("success", PlayArrowIcon, ExecutionLabels.RU);
    case ExecutionKeys.ST:
      return createChipData("warning", PauseIcon, ExecutionLabels.ST);
    default:
      return createChipData(
        "info",
        DriveFileRenameOutlineIcon,
        ExecutionLabels.DR
      );
  }
};

const CAMPAIGN_STEP_LABELS = enumToArray(CampaignCreateStepLabels).map(
  ({ id, name }) => ({
    id,
    name,
  })
);
const CAMPAIGN_STEPS = Object.entries(CreateCampaignStepIndex).map(
  ([id, name]) => ({
    id,
    name,
  })
);

const emptyCampaign: INewCampaign = { id: 0, open: false };

const CAMPAIGN_LOCALE = Object.entries(CampaignLocaleNames).map(
  ([id, name]) => ({
    id: id,
    name: name,
  })
);

const navigateToCampaign = (campaign: ICampaign): string => {
  return campaign.execution !== ExecutionKeys.DR
    ? RouterConstants.CAMPAIGN.detail(campaign.id, CampaignDetailTabs.audience)
    : RouterConstants.CAMPAIGN.new(campaign.id);
};

const useCreateCampaignStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.app.constants.dialog.campaignWidth,
    },
    flexDirection: "column",
    display: "flex",
  },
  header: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px",
    zIndex: 2,
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 13,
    right: 13,
  },
  stepper: { justifyContent: "center" },
  button: {
    marginRight: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(3, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 2.5),
    },
  },
  boxSection: {
    padding: theme.spacing(0, 0.5, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 2.5, 2),
    },
  },
  boxSequence: {
    margin: theme.spacing(3, 6.5),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 8, 4),
    },
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    padding: theme.spacing(5, 5.5),
  },
  boxSequenceHide: {
    height: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
    zIndex: 0,
    position: "relative",
    border: 0,
    borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
  },
  label: {
    "&.Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  labelContainer: {
    "& > .MuiStepLabel-label": {},
  },
  link: {
    display: "block",
  },
  step: {
    maxWidth: 215,
    padding: 0,
  },
  stepRoot: {
    cursor: "pointer",
  },
  connector: {
    top: 30,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)",
  },
}));

interface ICampaignStats {
  counts: {
    active?: Record<number, number>;
    failed?: Record<
      number,
      {
        by_action_nr: Record<string, Record<string, number>>;
      }
    >;
  };
}

const getStartedCount = (
  campaignId: number,
  dataStats: ICampaignStats | undefined
): number => {
  const active = dataStats?.counts.active?.[campaignId] || 0;
  // const failures = dataStats?.counts.failed?.[campaignId]?.by_action_nr;

  // let firstStepFailures = 0;
  // if (failures) {
  //   // Sum all failures from step 1 across all failure categories
  //   firstStepFailures = Object.values(failures).reduce((sum, categoryData) => {
  //     return sum + (categoryData["1"] || 0);
  //   }, 0);
  // }

  // return active + firstStepFailures;
  return active;
};

export {
  emptyCampaign,
  getStatusChipData,
  CAMPAIGN_STEPS,
  CAMPAIGN_LOCALE,
  CAMPAIGN_STEP_LABELS,
  useCreateCampaignStyles,
  navigateToCampaign,
  getStartedCount,
};
